<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :persistent="window > 0"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{on}">
      <v-btn color="color3" text v-on="on">
        <v-icon class="mr-2">fas fa-badge-check</v-icon> Verify Results
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>
          Verify Metrics - Step {{window + 1}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" small fab @click.stop="dialog=false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-subtitle-1">
        <v-window v-model="window">
          <v-window-item :key="0">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  To verify {{player.firstName}}'s Key Metrics select, the metrics that you persoanlly measured.
                </v-col>
              </v-row>
              <v-row dense>
                <v-checkbox
                  v-for="m in metrics"
                  :key="m.value"
                  :label="m.text"
                  v-model="m.selected"
                  class="shrink ml-2"
                  color="success"
                >
                </v-checkbox>
              </v-row>
            </v-container>
          </v-window-item>
          <v-window-item :key="1">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  How were the athleticism metrics tested?
                </v-col>
              </v-row>
              <v-row dense>
                <v-radio-group v-model="testType">
                  <v-radio color="success" label="Indoor or on a hard surface" value="indoor"></v-radio>
                  <v-radio color="success" label="In the sand or on a beach" value="sand"></v-radio>
                </v-radio-group>
              </v-row>
            </v-container>
          </v-window-item>
          <v-window-item :key="2">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  You, <b>{{user.fullname}}</b>, are verifying {{player.fullname}}'s {{selected.map(m => m.text) | formatArray }} on behalf of
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-select
                    :items="user.pages"
                    item-text="name"
                    item-value="id"
                    v-model="behalfOf"
                    label="Organization"
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <date-time-picker
                    type="date"
                    :datetime.sync="dtVerified"
                    label="Measured On - "
                    key="dtVerified"
                  ></date-time-picker>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row dense v-if="!!(behalfOf && dtVerified)">
                  <v-col cols="12">
                    <ol class="fa-ul">
                      <li>
                        <span class="fa-li"><i class="fad fa-check-circle success--text"></i></span>
                        Once you click save you will need to contact support@volleyballlife.com to remove a verification.
                      </li>
                      <li>
                        <span class="fa-li"><i class="fad fa-check-circle success--text"></i></span>
                        To change or update a verification simply repeat this process
                      </li>
                    </ol>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-container>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions :class="`justify-${ window === 0 ? 'end' : 'space-between'}`">
        <v-btn
          color="color3 color3Text--text"
          fab small
          @click.stop="back"
          v-if="window > 0"
        >
          <v-icon>fas fa-caret-left</v-icon>
        </v-btn>
        <v-btn
          :color="`${window === 2 ? 'success white--text' : 'color3 color3Text--text'}`"
          fab small
          @click.stop="next"
          :disabled="!valid"
          :loading="loading"
        >
          <v-icon>{{window === 2 ? 'fas fa-save' : 'fas fa-caret-right'}}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'

export default {
  props: ['player'],
  data () {
    return {
      dialog: false,
      window: 0,
      metrics: [
        { selected: false, text: 'Height', value: 'height' },
        { selected: false, text: 'Dominant Hand', value: 'dominantHand' },
        { selected: false, text: 'Standing Reach', value: 'reach' },
        { selected: false, text: 'Wingspan', value: 'wingspan' },
        { selected: false, text: 'Block Jump Touch', value: 'blockTouch' },
        { selected: false, text: 'Approach Jump Touch', value: 'approachTouch' },
        { selected: false, text: 'Star Drill', value: 'starDrill' }
      ],
      testType: null,
      behalfOf: null,
      dtVerified: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    dirty () {
      return false
    },
    selected () {
      return this.metrics.filter(f => f.selected)
    },
    selectedValues () {
      return this.metrics.filter(f => f.selected).map(m => m.value)
    },
    valid () {
      if (this.window === 0) return this.selected.length > 0
      if (this.window === 1) return !!this.testType
      if (this.window === 2) return !!(this.behalfOf && this.dtVerified)
      return false
    },
    dto () {
      return {
        selected: this.selectedValues,
        testType: this.testType,
        behalfOf: this.behalfOf,
        dtVerified: this.dtVerified
      }
    }
  },
  methods: {
    next () {
      if (this.window === 0) {
        this.window = this.selected.findIndex(f => ['blockTouch', 'approachTouch', 'starDrill'].includes(f.value)) === -1 ? 2 : 1
        return
      }
      if (this.window === 2) {
        this.save()
        return
      }
      this.window++
    },
    back () {
      if (this.window === 2) {
        this.window = this.selected.findIndex(f => ['blockTouch', 'approachTouch', 'starDrill'].includes(f.value)) === -1 ? 0 : 1
        return
      }
      this.window--
    },
    reset () {
      this.window = 0
      this.selected.forEach(f => { f.selected = false })
      this.testType = null
      this.behalfOf = null
      this.dtVerified = null
      this.loading = false
    },
    save () {
      this.loading = true
      this.$VBL.player.verification.post(this.player.ppId, this.dto)
        .then(r => {
          this.player.verifications.push(r.data)
          this.dialog = false
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        if (this.user.pages.length === 1) {
          this.behalfOf = this.user.pages[1].id
        }
      } else {
        this.reset()
      }
    }
  },
  components: {
    DateTimePicker
  }
}
</script>
